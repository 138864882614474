<template>
  <div v-if="checkPermission('list', 'action', 'sender-names')">
    <topic-component :topic="$t('SideBarItems.setting.list.senderID')"/>
    <b-card>
      <b-row>
        <b-col lg="3" md="3" sm="4">
          <b-form-group label-cols="12"
                        :label="$t('setting.senderID.list.senderID')" label-for="input-sm">
            <b-form-input v-model="form.sender_name"/>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="3" sm="4">
          <select-filter-component
              :label-select="$t('setting.senderID.list.status')"
              :options="statusList"
              v-model="form.status"
          />
        </b-col>
        <b-col lg="3" md="3" sm="4">
          <select-filter-component
              :label-select="$t('setting.senderID.list.typeOfUse')"
              :options="typeList"
              v-model="form.type"
          />
        </b-col>
        <b-col style="margin-top: 35px">
          <button type="button" class="btn waves-effect waves-light btn-gradient" @click="findSenderId">
            {{ $t('common.Search') }}
          </button>
          <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
            {{ $t('common.resetSearch') }}
          </button>
        </b-col>
      </b-row>
      <hr>
      <b-row align-v="center" class="pb-3">
        <b-col>
          <div class="d-flex align-items-center">
            <span class="mr-3">{{ $t('common.perPage') }}</span>
            <div>
              <b-form-select
                  v-model="form.$limit"
                  :options="pageOptions"
                  size="sm"
                  @change="findSenderId"
              />
            </div>
            <div class="ml-3">
              <total-list-component :total="totalAll"/>
            </div>
          </div>
        </b-col>
        <b-col align="end">
          <div class="d-flex justify-content-end" :class="{'mt-3 justify-content-center': innerWidth <= 768}">
            <public-sender-name
                v-if="checkPermission('random', 'action', 'master-sender-names') && checkPermission('create', 'action', 'sender-names')"/>
            <b-button variant="primary" @click="createSender"
                      :disabled="!checkPermission('create', 'action', 'sender-names')"><i
                class="fas fa-plus-circle"></i>
              {{ $t("setting.requestNew") }}
            </b-button>
          </div>
          <div v-if="is_using_trial || current_plan_id === 'null'">
            <label class="text-danger">{{ $t('common.buyPackage') }}</label>
          </div>
        </b-col>
      </b-row>
      <b-table
          responsive
          class="mb-0"
          :items="items"
          :fields="fields"
          :busy="isBusy"
          :empty-text="$t('empty')"
          show-empty
      >
        <template #head()="{ label, field: { key, sortable }}">
          {{ $t(label) }}
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong> {{ $t('loading') }}</strong>
          </div>
        </template>
        <template #empty="scope">
          <empty-table/>
        </template>
        <template #cell(status)="data">
          <b-badge pill :class="`px-2 text-white badge ${colorButton(data.item.status)}`">
            {{ filterValue(statusList, data.item.status) }}
          </b-badge>
        </template>
        <template #cell(createdAt)="data">
          {{ changeDateFormat(data.item.createdAt) }}
        </template>
        <template #cell(type)="data">
          <b-badge pill :class="`px-2 text-white badge ${data.item.type === 'otp' ? 'bg-info' :'bg-success' }`">
            {{ filterValue(typeList, data.item.type) }}
          </b-badge>
        </template>
      </b-table>
      <b-row>
        <div class="ml-auto">
          <b-pagination
              v-model="form.$skip"
              :total-rows="totalRows"
              :per-page="form.$limit"
              align="fill"
              class="my-0"
              size="sm"
              @input="findSenderId"
          ></b-pagination>
        </div>
      </b-row>
      <!--      <create-sender-id @output="findSenderId"/>-->
    </b-card>
    <div v-if="userInfo">
      <VerifyModal @update="updateVerifyInfoSuccess" :customer-profile="userInfo"/>
    </div>
  </div>
</template>

<script>
import CreateSenderId from "@/views/setting/senderID/create";
import PublicSenderName from "./publicSender.vue";
import TopicComponent from "@/components/common/topicComponent";
import masterData from "@/common/masterData.json"
import SelectFilterComponent from "@/components/common/selectFilterComponent";
import senderIdApi from "@/repository/senderIdApi";
import functionsCommon from "@/common/functions"
import functions from "@/common/functions";
import EmptyTable from "@/components/common/emptyTable.vue";
import TotalListComponent from "@/components/common/totalList.vue";
import {mapState} from "vuex";
import VerifyModal from "@/views/account/verify/index.vue";

export default {
  name: "SenderId",
  components: {
    VerifyModal,
    TotalListComponent, EmptyTable, SelectFilterComponent, TopicComponent, CreateSenderId, PublicSenderName
  },
  data() {
    return {
      totalAll: 0,
      form: {
        sender_name: '',
        status: null,
        type: null,
        $skip: 1,
        $limit: 10,
        is_only_own: 1
      },
      is_using_trial: JSON.parse(localStorage.getItem("is_using_trial")),
      current_plan_id: localStorage.getItem("current_plan_id"),
      fields: [
        {
          key: "createdAt",
          label: "setting.senderID.list.createdAt",
        },
        {
          key: "sender_name",
          label: "setting.senderID.list.senderID",
        },
        // {
        //   key: "company_name",
        //   label: "setting.senderID.list.company_or_personal",
        // },
        {
          key: "type",
          label: "setting.senderID.list.typeOfUse",
        },
        {
          key: "status",
          label: "setting.senderID.list.status",
        },
      ],
      isBusy: false,
      items: [],
      totalRows: 1,
      pageOptions: [10, 15, 50, 100],
      statusList: masterData.senderStatusList,
      typeList: masterData.typeList,
    }
  },
  computed: {
    ...mapState({
      innerWidth: state => state.theme.width_inner,
    }),
    BrandSetting() {
      return JSON.parse(localStorage.getItem('brand_setting'))
    },
    userInfo() {
      return this.$store.getters["profile/getProfile"]
    }
  },
  mounted() {
    this.findSenderId()
  },
  methods: {
    createSender() {
      if (this.userInfo?.identity_verify_status === 'verified') {
        this.$router.push({name: 'CreatSenderName'})
      } else if (this.userInfo?.identity_verify_status === 'waiting-approve') {
        this.$swal.fire({
          imageUrl:require('@/assets/images/icon/verify_waiting.svg'),
          title: this.$t('common.not_been_approve'),
          text: this.$t('common.please_check_your_auth_status_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_check_your_auth_status_2'),
          showCancelButton: true,
          confirmButtonColor: '#19CBAC',
          cancelButtonColor: '#5B656E',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.check'),
          allowOutsideClick: false,
          width:'40em',
          padding:'1.25rem'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push('/Account').catch(() => {
            })
          }
        })
      } else if (this.userInfo.identity_verify_status === 'rejected') {
        this.$swal.fire({
          imageUrl:require('@/assets/images/icon/verify_failed.svg'),
          title: this.$t('common.not_been_success_auth'),
          text: this.$t('common.please_re_auth_enable_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_re_auth_enable_2'),
          showCancelButton: true,
          confirmButtonColor: '#19CBAC',
          cancelButtonColor: '#5B656E',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.verify'),
          allowOutsideClick: false,
          width:'40em',
          padding:'1.25rem'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push('/Account').catch(() => {
            })
          }
        })
      } else {
        this.$swal.fire({
          imageUrl:require('@/assets/images/icon/verify.svg'),
          title: this.$t('common.has_not_been_auth'),
          text: this.$t('common.please_verify_your_credential_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_verify_your_credential_2'),
          showCancelButton: true,
          confirmButtonColor: '#19CBAC',
          cancelButtonColor: '#5B656E',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.verify'),
          allowOutsideClick: false,
          width:'40em',
          padding:'1.25rem'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$bvModal.show('modal-verify-account')
          }
        })
      }

    },
    changeDateFormat(date) {
      return date ? functionsCommon.changeDateFormat(date) : '-'
    },
    colorButton(data) {
      switch (data) {
        case 'active':
          return 'bg-success';
        case 'disapproved':
          return 'bg-grey';
        case 'blocked':
          return 'bg-danger';
        case 'pending':
          return 'bg-warning';
        default:
          return
      }
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    findSenderId() {
      this.isBusy = !this.isBusy
      senderIdApi.getAll(_.cloneDeep(this.form)).then(response => {
        this.totalAll = response.total
        this.totalRows = response.total
        this.items = response.data
      }).finally(() => {
        this.isBusy = !this.isBusy
      })
    },
    resetSearch() {
      this.form = {
        sender_name: '',
        status: null,
        type: null,
        $skip: 1,
        $limit: 10,
        is_only_own: 1
      }
      this.findSenderId()
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    updateVerifyInfoSuccess() {
      this.$router.push('/Account').catch(() => {
      })
    }
  }
};
</script>
