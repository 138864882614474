import service from './index'

const resourcePath = '/master-sender'
export default {

    random(search = {}) {
        return service.apiAuth.post(`${resourcePath}/random`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    getLatest(search = {}) {
        return service.apiAuth.get(`${resourcePath}/get-latest`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
}