<template>
  <b-modal
      id="modal-create-sender-id"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
      scrollable
  >
    <template #modal-header="{ close }">
      <h5>{{ $t('setting.requestNew') }}</h5>
      <i class="fas fa-times hover-icon action-button" @click="clearData()"></i>
    </template>
    <b-row>
      <b-col sm="12" lg="7" md="7">
        <div>
          <input-component :label-input="$t('setting.senderID.form.senderID')"
                           v-model="$v.form.sender_name.$model"
                           max="11" required
                           :require-detail="$t('setting.senderID.form.detail')"
                           :state="validateState('sender_name')" @keypress="isOnlyEnglishNumBer"
                           :require-label="validateState('sender_name') !== null && !$v.form.sender_name.required  ? $t('common.requiredField') : ''"
                           :disabledType="disabledSenderName"
          />
          <select-component :label-select="$t('setting.senderID.form.typeOfUse')"
                            :options="typeList" required
                            v-model="$v.form.type.$model"
                            :state="validateState('type')"
                            :require-label="validateState('type') && !$v.form.type.required  ? $t('common.requiredField') : ''"
                            :disabledType="disabledSenderName"
          />
          <select-component :label-select="$t('setting.senderID.form.juristic_type')"
                            :options="juristic_type_list" required
                            v-model="$v.form.juristic_type.$model"
                            :state="validateState('juristic_type')"
                            :require-label="validateState('juristic_type') && !$v.form.juristic_type.required  ? $t('common.requiredField') : ''"
          />
          <input-component
              :label-input="form.juristic_type === 'juristic' ? $t('setting.senderID.form.companyName') :  $t('setting.senderID.form.personalName')"
              v-model="$v.form.company_name.$model" required
              :state="validateState('company_name')"
              :require-label="validateState('company_name') !== null && !$v.form.company_name.required  ? $t('common.requiredField') : ''"

          />
          <input-component class="mt-2" v-model="$v.form.tax_id.$model"
                           required
                           max="13"
                           :state="validateState('tax_id')"
                           :require-label="validateState('tax_id') !== null && !$v.form.tax_id.required   ? $t('common.requiredField') : ''"
                           :label-input="form.juristic_type === 'juristic' ?  $t('subscription.checkout.tax_id') : $t('subscription.checkout.card_id')"/>

          <div>
            <input-component required :label-input="$t('setting.senderID.form.realCompanyOrStore')"
                             :state="validateState('company_url')"
                             :require-label="validateState('company_url') !== null && !$v.form.company_url.required   ? $t('common.requiredField') : ''"
                             v-model="$v.form.company_url.$model"/>
          </div>

          <strong class="label-for-input mt-2">
            {{ $t('setting.senderID.form.evidence') }}
          </strong>
          <strong class="ml-2" style="color: red">*</strong>
          <div>
            <div class="condition-verify mt-3 justify-content-between">
              <b-row>
                <b-col sm="12" md="12" lg="2" xl="1" class="text-center d-md-table-cell">
                  <img src="@/assets/images/icon/warning.svg" alt="warning icon">
                </b-col>
                <b-col>
                  <div class="title">{{$t('setting.senderID.form.condition_verify_title')}}</div>
                  <div class="content">
                    {{$t('setting.senderID.form.condition_verify')}}
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="mt-3">
              <upload-file-component
                  required
                  :label="form.juristic_type === 'juristic' ?  $t('setting.senderID.form.companyRegistrationDocument') :  $t('setting.senderID.form.personalRegistrationDocument')"
                  v-model="$v.form.company_file.$model"
                  :accept-file="'.png, .jpg, .jpeg, .pdf'"
                  :text-info="$t('setting.senderID.form.accept_file')"
                  :is-required-error="validateState('company_file')"

              />
            </div>
            <div>
              <input-text-area-component required :label-input="$t('setting.senderID.form.textSnippet')" rows="5"
                                         v-model="form.text_snippet"

              />
            </div>
          </div>
        </div>

      </b-col>
      <b-col sm="12" lg="5" md="5">
        <label class="label-for-input ml-4 mr-4">
          {{ $t('setting.senderID.conditions') }}
        </label>
        <div class=" ml-4 mr-4" v-html="$t('setting.senderID.note')"/>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-row align-h="end" class="mr-1 mt-3">
        <b-button variant="primary"
                  :disabled="$v.form.$invalid || loading"
                  @click="createSender">
          <b-spinner v-if="loading" small class="mr-2"></b-spinner>
          <i v-else class="fas fa-save"/> {{ $t('button.save') }}
        </b-button>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import InputComponent from "@/components/common/inputComponent";
import InputTextAreaComponent from "@/components/common/textareaComponent";
import SelectComponent from "@/components/common/selectComponent";
import {validationMixin} from "vuelidate";
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import masterData from "@/common/masterData.json"
import senderIdApi from "@/repository/senderIdApi";
import alert from "@/common/alert";
import functionsCommon from "@/common/functions"
import UploadFileComponent from "@/components/common/uploadFileComponent";
import functions from "@/common/functions";

export default {
  name: "createSenderId",
  components: {UploadFileComponent, SelectComponent, InputTextAreaComponent, InputComponent},
  mixins: [validationMixin],
  data() {
    return {
      documentRegister: "",
      form: {
        sender_name: "",
        type: null,
        tax_id: null,
        company_url: "",
        company_name: "",
        company_file: "",
        text_snippet: "",
        juristic_type: "personal",
        is_master_sender: false
      },
      loading: false,
      typeList: masterData.typeList,
      juristic_type_list: masterData.juristic_list,
      disabledSenderName: false,
    }
  },
  validations: {
    form: {
      sender_name: {
        required
      },
      tax_id: {
        required,
        maxLength: maxLength(13),
        minLength: minLength(7)
      },
      type: {
        required
      },
      company_name: {
        required
      },
      text_snippet: {
        required
      },
      juristic_type: {
        required
      },
      company_url: {
        required
      },
      company_file: {
        required
      }
    },
  },
  mounted() {
    const perm = this.checkPermission('create', 'action', 'sender-names')
    const hash = this.$route.hash
    if (hash === '#modal-create-sender-id' && perm) {
      this.$bvModal.show('modal-create-sender-id')
    }

    // Watch for modal open/close to call functions
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'modal-create-sender-id') {
        this.checkingQuery();
      }
    })
  },
  methods: {
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username];
      return $dirty ? !$error : null;
    },
    deleteFile() {
      this.form.company_file = ""
    },
    chkNumber(e) {
      return functionsCommon.checkNumber(e)
    },
    createSender() {
      this.loading = true
      senderIdApi.create(this.form).then(response => {
        if (response?.codeSYS === '001') {
          this.$emit('output', 'success');
          this.clearData()
          alert.addNewSuccess(this)
        } else {
          alert.addNewFailed(this, response.message)
          this.loading = false
        }
      }).catch(err => {
        this.loading = false
        alert.addNewFailed(this, err.response?.data?.message)
      })
    },
    isOnlyEnglishNumBer(e) {
      return functionsCommon.isOnlyEnglishNumberAndUnderscore(e)
    },
    clearData() {
      this.form = {
        sender_name: "",
        tax_id: null,
        type: null,
        company_url: "",
        company_name: "",
        company_file: "",
        text_snippet: "",
        juristic_type: "personal",
        is_master_sender: false
      }
      this.disabledSenderName = false;
      this.$v.form.$reset()
      this.loading = false
      this.$bvModal.hide('modal-create-sender-id')
      if (this.$route.query?.senderName) {
        this.$router.replace({query: null});
      }
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    checkingQuery() {
      const perm = this.checkPermission('random', 'action', 'master-sender-names')
      if (this.$route.query?.senderName && perm) {
        this.form.sender_name = this.$route.query.senderName;
        this.form.is_master_sender = true
        this.disabledSenderName = true;
        this.form.type = "mkt";
      }
    }
  },
  watch: {
    'form.sender_name'(newVal) {
      this.form.sender_name = newVal.replace(" ", "")
    },
  }
}
</script>
<style scoped>
.condition-verify {
  padding: 1rem;
  width: 100%;
  color: #2F2E2E;
  background: #FFF6E8;
  border-radius: 20px;

  .title {

  }

  .content {
    font-size: 12px;
    margin-top: .5rem;
    word-break: break-word;
    //text-wrap: wrap;
  }
}
</style>

