<template>
  <div>
    <button  class="mr-2 public-sender-btn" @click="openModal">
      <template class="d-flex">
        <img src="@/assets/images/icon/package.svg" alt="package" width="15" class="mb-1">
        {{ $t('publicSender.publicSender') }}
      </template>
    </button>
    <b-modal id="public-sender-name" size="lg" no-close-on-backdrop no-close-on-esc scrollable>
      <template #modal-header="{ close }">
        <h5>{{ $t('publicSender.publicSender') }}</h5>
        <i class="fas fa-times hover-icon action-button" @click="clearData()"></i>
      </template>
      <b-row>
        <b-col>
          <div>{{ $t('publicSender.randomSenderName') }}</div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="confetti-body">
            <div v-if="totalRandom < maxRandom && senderName" class="container">
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div class="confetti"></div>
              <div v-if="senderName" class="sender-name position-absolute-mid text-shadow">{{ senderName }}</div>
            </div>
            <div v-else-if="totalRandom == maxRandom" class="container">
              <div class="position-absolute-mid">
                <div class="flex-center">
                  <img class="mx-auto mb-2" src="@/assets/images/icon/checkmark-outline.svg" alt="success">
                </div>
                <div class="font-size-16 text-shadow">{{ $t('publicSender.maxForToDay') }}</div>
              </div>
            </div>
            <div v-else-if="!loading" class="container">
              <div class="position-absolute-mid">
                <div class="flex-middle">
                  <img src="@/assets/images/random.gif" alt="star-grow" style="width: 100px;"/>
                </div>
              </div>
            </div>
            <div v-if="loading" class="container">
              <div class="position-absolute-mid">
                <div class="flex-middle">
                  <div style="width: 100%; max-width: 300px;">
                    <div class="flex-center text-center sender-name">
                      <div class="text-loading-box">{{ textDigit1 }}</div>
                      <div class="text-loading-box">{{ textDigit2 }}</div>
                      <div class="text-loading-box">{{ textDigit3 }}</div>
                      <div class="text-loading-box">{{ textDigit4 }}</div>
                      <div class="text-loading-box">{{ textDigit5 }}</div>
                      <div class="text-loading-box">{{ textDigit6 }}</div>
                      <div class="text-loading-box">{{ textDigit7 }}</div>
                      <div class="text-loading-box">{{ textDigit8 }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <template #modal-footer class="">
        <b-container>
          <b-row v-if="(totalRandom == 0 && newRandom == 0) || !senderName">
            <b-col>
              <b-button variant="primary"
                        :disabled="loading || totalRandom + newRandom >= maxRandom || delayEnableButton > 0" block
                        @click="randomSenderName">
                <b-spinner v-if="loading" small class=""></b-spinner>
                {{ $t('publicSender.randomSender') }}
                {{ delayEnableButton > 0 ? '(' + delayEnableButton + ')' : '' }}
              </b-button>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col cols="6">
              <b-button variant="outline"
                        :disabled="loading || totalRandom + newRandom >= maxRandom || delayEnableButton > 0" block
                        @click="randomSenderNameAgain">
                <b-spinner v-if="loading" small class=""></b-spinner>
                {{ $t('publicSender.randomSenderAgain') }}
                {{ delayEnableButton > 0 ? '(' + delayEnableButton + ')' : '' }}
              </b-button>
            </b-col>
            <b-col cols="6">
              <b-button variant="primary" block @click="createSender">
                {{ $t('publicSender.requestNew') }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              <span>{{ totalRandom + newRandom }}</span>/{{ maxRandom }}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="font-weight-500 mt-4" style="color: #2F2E2E">{{ $t('publicSender.Notes') }}</b-col>
            <b-col cols="12" class="mt-2" style="color: #9F9F9F">
              {{ $t('publicSender.remark') }}
            </b-col>
            <b-col cols="12" class="mt-1" style="color: #9F9F9F">
              <ul>
                <li>{{ $t('publicSender.remark_1_1') }} {{ maxRandom }} {{ $t('publicSender.remark_1_2') }}</li>
                <li>{{ $t('publicSender.remark_2') }}</li>
              </ul>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </div>
</template>

<script>
import functions from "@/common/functions";
import {mapState} from "vuex";
import masterSenderApi from '@/repository/masterSenderApi'
import alert from "@/common/alert";

export default {
  name: "public-sender-name",
  computed: {
    ...mapState({
      primaryColor: state => state.theme.primaryColor,
    }),
    BrandSetting() {
      return JSON.parse(localStorage.getItem('brand_setting'))
    },
    userInfo() {
      return this.$store.getters["profile/getProfile"]
    }
  },
  data() {
    return {
      loading: false,
      totalRandom: 0,
      newRandom: 0,
      maxRandom: 5,
      senderName: "",
      delayEnableButton: 0,
      textDigit1: "",
      textDigit2: "",
      textDigit3: "",
      textDigit4: "",
      textDigit5: "",
      textDigit6: "",
      textDigit7: "",
      textDigit8: "",
    }
  },
  mounted() {
    if (this.checkPermission('random', 'action', 'master-sender-names') && this.checkPermission('create', 'action', 'sender-names')) {
      this.checkLatest();
      this.$bvModal.show('confirm-random-sender-name');
    }
  },
  methods: {
    checkLatest() {
      try {
        this.loading = true;
        masterSenderApi.getLatest().then((res) => {
          this.totalRandom = res.total_random || 0;
          this.newRandom = 0;
          // this.senderName = "";
        }).catch(err => {
          alert.fail(this, err?.message)
        })
      } catch (error) {
        alert.fail(this, err?.message)
      } finally {
        this.loading = false;
      }
    },
    openModal() {
      if ((this.userInfo?.identity_verify_status === 'verified' && this.userInfo?.is_using_trial) || (this.userInfo?.identity_verify_status === 'verified' && this.userInfo?.is_using_default) || this.userInfo?.identity_verify_status === 'verified') {
        this.$bvModal.show('public-sender-name');
      } else if (this.userInfo?.identity_verify_status === 'waiting-approve') {
        this.$swal.fire({
          imageUrl:require('@/assets/images/icon/verify_waiting.svg'),
          title: this.$t('common.not_been_approve'),
          text: this.$t('common.please_check_your_auth_status_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_check_your_auth_status_2'),
          showCancelButton: true,
          confirmButtonColor: '#19CBAC',
          cancelButtonColor: '#5B656E',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.check'),
          allowOutsideClick: false,
          width:'40em',
          padding:'1.25rem'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push('/Account').catch(() => {
            })
          }
        })
      } else if (this.userInfo.identity_verify_status === 'rejected') {
        this.$swal.fire({
          imageUrl:require('@/assets/images/icon/verify_failed.svg'),
          title: this.$t('common.not_been_success_auth'),
          text: this.$t('common.please_re_auth_enable_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_re_auth_enable_2'),
          showCancelButton: true,
          confirmButtonColor: '#19CBAC',
          cancelButtonColor: '#5B656E',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.verify'),
          allowOutsideClick: false,
          width:'40em',
          padding:'1.25rem'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push('/Account').catch(() => {
            })
          }
        })
      } else {
        this.$swal.fire({
          imageUrl:require('@/assets/images/icon/verify.svg'),
          title: this.$t('common.has_not_been_auth'),
          text: this.$t('common.please_verify_your_credential_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_verify_your_credential_2'),
          showCancelButton: true,
          confirmButtonColor: '#19CBAC',
          cancelButtonColor: '#5B656E',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.verify'),
          allowOutsideClick: false,
          width:'40em',
          padding:'1.25rem'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$bvModal.hide('public-sender-name');
            this.$bvModal.show('modal-verify-account')
          }
        })
      }

    },
    clearData() {
      this.$bvModal.hide('public-sender-name');
      this.senderName = "";
      this.checkLatest();
    },
    randomSenderNameAgain() {
      this.$swal
          .fire({
            icon: 'warning',
            title: this.$t('publicSender.confirmNewRadom') + "?",
            text: this.$t('publicSender.modalWarning'),
            showCancelButton: true,
            confirmButtonColor: '#7367F0',
            cancelButtonColor: '#EA5455',
            cancelButtonText: this.$t('button.cancel'),
            confirmButtonText: this.$t('publicSender.confirmRandom'),
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.randomSenderName()
            }
          })
    },
    randomSenderName() {
      this.loading = true;
      this.senderName = "";
      this.randomTextDigit();
      setTimeout(() => {
        try {
          masterSenderApi.random().then((res) => {
            this.senderName = res.random_sender;
            // this.totalRandom = res.total_random;
            this.newRandom++;
          }).catch(err => {
            if (err.response.status == 404) {
              this.$swal.fire({
                icon: 'error',
                title: this.$t('publicSender.randomFailed'),
                html: "<p style='white-space: pre-line'>" + this.$t('publicSender.senderNameNot') + "</p>",
                confirmButtonColor: '#3085d6',
              }).then((result) => {
                this.$bvModal.hide('public-sender-name');
              })
            } else {
              alert.fail(this, err?.message)
            }
            this.checkLatest();
          })
        } catch (error) {
          alert.fail(this, err?.message)
        } finally {
          this.loading = false;
          this.delayEnableButton = 3;
          this.loopDelayEnableButton();
        }
        // BA need this delay to show the animation
      }, 1400);
    },
    loopDelayEnableButton() {
      if (this.delayEnableButton > 0) {
        setTimeout(() => {
          this.delayEnableButton--;
          this.loopDelayEnableButton();
        }, 1000);
      }
    },
    createSender() {
      setTimeout(() => {
        this.$bvModal.hide('public-sender-name');
        this.$router.push({
          query: {
            senderName: this.senderName
          }
        });
        this.newRandom--;
        this.totalRandom++;
        // this.$bvModal.show('modal-create-sender-id');
        this.$router.push({
          name: 'CreatPublicSenderName',
          query: {
            senderName: this.senderName
          }
        })
        setTimeout(() => {
          this.senderName = "";
          this.checkLatest();
        }, 1000)
      }, 0)
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    randomTextDigit() {
      if (this.loading) {
        setTimeout(() => {
          setTimeout(() => {
            this.textDigit1 = this.makeid(1);
          }, 50);
          setTimeout(() => {
            this.textDigit2 = this.makeid(1);
          }, 30);
          setTimeout(() => {
            this.textDigit3 = this.makeid(1);
          }, 130);
          setTimeout(() => {
            this.textDigit4 = this.makeid(1);
          }, 100);
          setTimeout(() => {
            this.textDigit5 = this.makeid(1);
          }, 70);
          setTimeout(() => {
            this.textDigit6 = this.makeid(1);
          }, 170);
          setTimeout(() => {
            this.textDigit7 = this.makeid(1);
          }, 100);
          setTimeout(() => {
            this.textDigit8 = this.makeid(1);
          }, 10);
          this.randomTextDigit();
        }, 150);
      }
    },
    makeid(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    }
  },
}
</script>
<style scoped lang="scss">
.confetti-body {
  width: 100%;
  height: 180px;
  overflow: hidden;
  position: relative;

  .container {
    width: 100%;
    height: 100%;
  }

  .confetti {
    width: 15px;
    height: 15px;
    background-color: #f2d74e;
    position: absolute;
    left: 50%;
    animation: confetti 5s ease-in-out -2s infinite;
    transform-origin: left top;
  }

  .confetti:nth-child(1) {
    background-color: #f2d74e;
    left: 10%;
    animation-delay: 0;
  }

  .confetti:nth-child(2) {
    background-color: #95c3de;
    left: 90%;
    animation-delay: -5s;
  }

  .confetti:nth-child(3) {
    background-color: #ff9a91;
    left: 30%;
    animation-delay: -3s;
  }

  .confetti:nth-child(4) {
    background-color: #f2d74e;
    left: 40%;
    animation-delay: -2.5s;
  }

  .confetti:nth-child(5) {
    background-color: #95c3de;
    left: 50%;
    animation-delay: -4s;
  }

  .confetti:nth-child(6) {
    background-color: #ff9a91;
    left: 60%;
    animation-delay: -6s;
  }

  .confetti:nth-child(7) {
    background-color: #f2d74e;
    left: 70%;
    animation-delay: -1.5s;
  }

  .confetti:nth-child(8) {
    background-color: #95c3de;
    left: 80%;
    animation-delay: -2s;
  }

  .confetti:nth-child(9) {
    background-color: #ff9a91;
    left: 90%;
    animation-delay: -3.5s;
  }

  .confetti:nth-child(10) {
    background-color: #f2d74e;
    left: 100%;
    animation-delay: -2.5s;
  }

  .confetti:nth-child(11) {
    background-color: #f2d74e;
    left: 95%;
    animation-delay: 0.8;
  }

  .confetti:nth-child(12) {
    background-color: #95c3de;
    left: 30%;
    animation-delay: -4.9s;
  }

  .confetti:nth-child(13) {
    background-color: #ff9a91;
    left: 15%;
    animation-delay: -3.3s;
  }

  .confetti:nth-child(14) {
    background-color: #95c3de;
    left: 55%;
    animation-delay: -2.8s;
  }

  .confetti:nth-child(15) {
    background-color: #f2d74e;
    left: 45%;
    animation-delay: -4.3s;
  }

  .confetti:nth-child(16) {
    background-color: #ff9a91;
    left: 35%;
    animation-delay: -3.3s;
  }

  .confetti:nth-child(17) {
    background-color: #f2d74e;
    left: 25%;
    animation-delay: -1s;
  }

  .confetti:nth-child(18) {
    background-color: #95c3de;
    left: 25%;
    animation-delay: -3.8s;
  }

  .confetti:nth-child(19) {
    background-color: #ff9a91;
    left: 70%;
    animation-delay: -2.5s;
  }

  .confetti:nth-child(20) {
    background-color: #f2d74e;
    left: 0%;
    animation-delay: -3s;
  }

  @keyframes confetti {
    0% {
      transform: rotateZ(15deg) rotateY(0deg) translate(0, 0);
    }

    25% {
      transform: rotateZ(5deg) rotateY(360deg) translate(-20px, 40px);
    }

    50% {
      transform: rotateZ(15deg) rotateY(720deg) translate(20px, 120px); // 3
    }

    75% {
      transform: rotateZ(5deg) rotateY(1080deg) translate(-40px, 160px); // 4
    }

    100% {
      transform: rotateZ(15deg) rotateY(1440deg) translate(40px, 210px); //5.5
    }
  }

  @keyframes senderPopIn {
    0% {
      font-size: 24px;
      opacity: 0;
    }

    75% {
      font-size: 56px;
      opacity: 1;
    }

    100% {
      font-size: 48px;
    }
  }

  .sender-name {
    color: #456DB9;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    animation: senderPopIn 0.5s ease-in-out;
  }

  .text-shadow {
    text-shadow: 0 0 12px #fff, 0 0 8px #fff, 0 0 4px #fff;
  }

  .position-absolute-mid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

@keyframes animate__pulse {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(0.9);
  }

  75% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

.mail-animation {
  height: 100%;
  animation: animate__pulse 1s infinite;
}

.text-loading-box {
  width: 26px;
  margin: 0 2px;
  font-size: 38px;
}
</style>

<style>
#public-sender-name .modal-footer {
  padding-top: 0px;
}

#public-sender-name .modal-body {
  padding-bottom: 8px;
}


/* CSS */
.public-sender-btn {
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: black;
  display: block;
  border: 0;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(45deg, #ffc82f 0%, #F09819 51%, #ffc82f 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border-radius:30px;
  padding: 0.454rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.public-sender-btn:hover {
  background-position: right center!important;
  color: black!important;
  text-decoration: none!important;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(45deg, #ffc82f 0%, #F09819 51%, #ffc82f 100%);
}

.public-sender-btn:active {
  transform: scale(0.95)!important;
}
</style>